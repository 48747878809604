<template>
  <b-card title="Contact us">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <a href="">Add Contact us</a>
        </template>

        <AddContactUs @add-category="getData" />
      </b-tab>
      <b-tab @click="$router.push('/all-contact-us')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/all-categories">All Categories</a> -->
          <span>All Contact us</span>
        </template>
        <router-view />
        <!-- <CategoriesList ref="allData"></CategoriesList> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import AddContactUs from './AddContactUs.vue'

export default {
  components: {
  
    BCard,
 
    BTabs,
    BTab,

    AddContactUs,
  },
  setup() {
    const allData = ref()

    const getData = () => {

      // allData.value.getCategories()

    }
    return {
      getData,
      allData,

    }
  },
}
</script>
